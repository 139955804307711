/* app.css */

/* Import Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* Set font family */
.body {
  font-family: 'Inter', sans-serif;
}
.main-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.main-container {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.lable-style {
  @apply  text-black  text-opacity-60  font-semibold my-2 flex flex-col gap-y-2 relative;
}

.input-style {
  @apply text-black font-normal  placeholder:text-grey-75 border-2 border-grey-75 rounded-md p-2  focus:outline-none;
}

.country-code {
  @apply absolute left-2 top-1 h-[80%]  bg-white pr-2 border-r-2 border-r-grey-75 w-[50px] text-black text-opacity-60 font-semibold border-none outline-none focus:outline-none focus:border-none;
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
     appearance: none; 
    -moz-appearance: textfield;
}

.lable-style-2 {
  @apply  text-blue-25  text-opacity-60  font-semibold my-2 flex flex-col gap-y-2 relative w-full;
}

.error-style {
  @apply text-xs text-red-25 absolute -bottom-5 left-1;
}
